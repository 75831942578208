import React, { useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import "react-range-slider-input/dist/style.css";
import { NameContext } from "./Index";
import {
  getEngagementCollectionAction,
  getJewelryCollectionAction,
  getWeddingCollectionAction,
} from "../../features/Collections/collectionSlice";
import { fetchProducts } from "../../features/productsList/productsListSlice";
// import { colorsVariant } from "../../constants/utils";

const FilterModal = ({ activeCollection }) => {
  const { setName } = useContext(NameContext);
  //currentuse
  const dispatch = useDispatch();
  const [activeFilter, setActiveFilter] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState({
    price: [],
    metal_type: [],
    carat: [],
    shape: [],
    instock: [],
    sort_by: [],
  });
  const [countryCurrency, setCountryCurrency] = useState("INR");

  useEffect(() => {
    setCountryCurrency(localStorage.getItem("currency") || "INR");
  }, []);

  const conversionRates = {
    INR: 1,
    USD: 0.012,
    GBP: 0.011,
    CAD: 0.016,
  };

  const convertPrice = (price) => {
    const rate = conversionRates[countryCurrency] || 1;
    const convertedPrice = price * rate;
    return convertedPrice.toFixed(0);
  };

  const getCurrencySymbol = () => {
    switch (countryCurrency) {
      case "USD":
        return "$";
      case "GBP":
        return "€";
      case "CAD":
        return "C$";
      default:
        return "₹";
    }
  };

  const toggleFilter = (key) => {
    setActiveFilter(activeFilter === key ? null : key);
  };

  const handleCheckboxChange = (category, value) => {
    setSelectedFilters((prev) => {
      const updatedFilters = { ...prev };

      if (category === "metal_type") {
        const isSelected = prev[category]?.includes(value);
        if (isSelected) {
          updatedFilters[category] = prev[category].filter(
            (item) => item !== value
          );
          setName("white");
        } else {
          updatedFilters[category] = [value];
          setName(value);
        }
      } else if (category === "sort_by") {
        updatedFilters[category] = prev[category]?.includes(value)
          ? []
          : [value];
      } else {
        updatedFilters[category] = prev[category]?.includes(value)
          ? prev[category].filter((item) => item !== value)
          : [...(prev[category] || []), value];
      }

      return updatedFilters;
    });
  };

  useEffect(() => {
    if (!selectedFilters?.metal_type?.length && setName !== "white") {
      setName("white");
    }
  }, [selectedFilters, dispatch]);

  //JewelryCollection

  useEffect(() => {
    if (activeCollection === "jewelry") {
      const metal_type = selectedFilters.metal_type.join(",");
      const ss_price_filter = selectedFilters.price.join(",");
      const sort_by = selectedFilters.sort_by[0] || "";

      dispatch(
        getJewelryCollectionAction({ metal_type, ss_price_filter, sort_by })
      );
    }
  }, [selectedFilters, dispatch, activeCollection]);

  // Engagement Collection

  useEffect(() => {
    if (activeCollection === "engagement") {
      const metal_type = selectedFilters.metal_type.join(",");
      const ss_price_filter = selectedFilters.price.join(",");
      const sort_by = selectedFilters.sort_by[0] || "";

      dispatch(
        getEngagementCollectionAction({ metal_type, ss_price_filter, sort_by })
      );
    }
  }, [selectedFilters, dispatch, activeCollection]);

  //Wedding Collection

  useEffect(() => {
    if (activeCollection === "wedding") {
      const metal_type = selectedFilters.metal_type.join(",");
      const ss_price_filter = selectedFilters.price.join(",");
      const sort_by = selectedFilters.sort_by[0] || "";

      dispatch(
        getWeddingCollectionAction({ metal_type, ss_price_filter, sort_by })
      );
    }
  }, [selectedFilters, dispatch, activeCollection]);

  // Product list

  useEffect(() => {
    if (activeCollection === "products") {
      const category = localStorage.getItem("category");
      const page = 1;
      const metal_type = selectedFilters.metal_type.join(",");
      // const carat = selectedFilters.carat.join(",");
      const ss_price_filter = selectedFilters.price.join(",");
      const sort_by = selectedFilters.sort_by[0] || "";

      dispatch(
        fetchProducts({
          page,
          // carat,
          category,
          metal_type,
          ss_price_filter,
          sort_by,
        })
      );
    }
  }, [selectedFilters, dispatch, activeCollection]);

  const filters = {
    price: (
      <div className="checkbox-group">
        {[
          "1000-10000",
          "10001-20000",
          "20001-30000",
          "30001-40000",
          "40001-50000",
          "50001-60000",
          "60001-70000",
          "70001-80000",
          "80001-90000",
          "90001-100000",
          "100000-200000",
        ].map((range) => {
          const [min, max] = range.split("-").map(Number);
          return (
            <label key={range}>
              <input
                type="checkbox"
                value={range}
                checked={selectedFilters.price.includes(range)}
                onChange={() => handleCheckboxChange("price", range)}
              />
              {`${getCurrencySymbol()}${convertPrice(
                min
              )} - ${getCurrencySymbol()}${convertPrice(max)}`}
            </label>
          );
        })}
      </div>
    ),
    metal: (
      <div className="checkbox-group">
        {["White Gold", "Yellow Gold", "Rose Gold"].map((metal) => (
          <label key={metal}>
            <input
              type="checkbox"
              value={metal}
              checked={selectedFilters.metal_type.includes(metal)}
              onChange={() => handleCheckboxChange("metal_type", metal)}
            />
            {metal}
          </label>
        ))}
      </div>
    ),
    // carat: (
    //   <div className="checkbox-group">
    //     {[
    //       "1 - 1.99",
    //       "2 - 2.99",
    //       "3 - 3.99",
    //       "4 - 4.99",
    //       "5 - 5.99",
    //       "6 - 6.99",
    //       "7 - 7.99",
    //     ].map((carat) => (
    //       <label key={carat}>
    //         <input
    //           type="checkbox"
    //           value={carat}
    //           checked={selectedFilters.carat.includes(carat)}
    //           onChange={() => handleCheckboxChange("carat", carat)}
    //         />
    //         {carat}
    //       </label>
    //     ))}
    //   </div>
    // ),
    // shape: (
    //   <div className="checkbox-group">
    //     {[
    //       "Round",
    //       "Oval",
    //       "Cushion",
    //       "Emerald",
    //       "Pear",
    //       "Heart",
    //       "Radiant",
    //       "Princess",
    //       "Marquise",
    //       "Asscher",
    //       "Elongated",
    //     ].map((shape) => (
    //       <label key={shape}>
    //         <input
    //           type="checkbox"
    //           value={shape}
    //           checked={selectedFilters.shape.includes(shape)}
    //           onChange={() => handleCheckboxChange("shape", shape)}
    //         />
    //         {shape}
    //       </label>
    //     ))}
    //   </div>
    // ),
    // instock: (
    //   <div className="checkbox-group">
    //     <label>
    //       <input
    //         type="checkbox"
    //         value={"In Stock"}
    //         checked={selectedFilters.instock.includes("In Stock")}
    //         onChange={() => handleCheckboxChange("instock", "In Stock")}
    //       />
    //       In Stock
    //     </label>
    //   </div>
    // ),
    sort: (
      <div className="sort-select">
        {[
          "Newest",
          "Price: High to Low",
          "Price: Low to High",
          "Best Seller",
          "Exclusive",
        ].map((option) => (
          <label key={option}>
            <a
              href="#"
              className={`filter-link  ${
                selectedFilters.sort_by.includes(option) ? "selected " : ""
              }`}
              onClick={(e) => {
                e.preventDefault();
                handleCheckboxChange("sort_by", option);
              }}
            >
              {option}
            </a>
          </label>
        ))}
      </div>
    ),
  };

  return (
    <div className="filter-modal">
      <div className="filter-options">
        {Object.keys(filters).map((key) => (
          <div key={key} className="filter-container">
            <div
              className={`filter-title ${activeFilter === key ? "active" : ""}`}
              onClick={() => toggleFilter(key)}
              onMouseEnter={() => setActiveFilter(key)}
            >
              <span className="filter-title-text">
                {key.charAt(0).toUpperCase() + key.slice(1)}
              </span>
              {activeFilter === key ? (
                <FiChevronUp className="icon" />
              ) : (
                <FiChevronDown className="icon" />
              )}
            </div>
            {activeFilter === key && (
              <div
                className="filter-content"
                onMouseLeave={() => setActiveFilter(false)}
              >
                {filters[key]}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FilterModal;

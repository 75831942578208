/* eslint-disable no-restricted-globals */
import React, { useEffect, useState, createContext } from "react";
import { Breadcrumb, Container } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { useParams } from "react-router-dom";
import {
  newTransformProductData,
  transformProductData,
} from "../../constants/function";
import {
  addToFavourite,
  getFavouriteList,
  removeFavoriteList,
} from "../../features/cart/cartSlice";
import {
  fetchProducts,
  resetproductReducer,
} from "../../features/productsList/productsListSlice";
import { convertToTitleCase } from "../../helper/constant";
import formateSlug from "../../helper/formateSlug";
import CategoryComingSoonProducts from "../CategoryCominSoonProduct";
import Loader from "../UI/Loader";
import ProductCard from "./ProductCard";
import { CustomContainer } from "../common/global-display";
import FilterModal from "./FilterModal";
// import { dummyData } from "../../constants/utils";

export const NameContext = createContext({
  name: undefined,
  setName: () => {},
});

const NameContextProvider = ({ children }) => {
  const [name, setName] = useState(undefined);

  return (
    <NameContext.Provider value={{ name, setName }}>
      {children}
    </NameContext.Provider>
  );
};

const ProductList = () => {
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const { category } = useParams();
  const slug = formateSlug(category);
  const disptach = useDispatch();
  const productReducer = useSelector((state) => state.products);
  const useDetails = useSelector((state) => state.userDetails.user);
  const [products, setProducts] = useState([]);
  const [showFilterModal, setShowFilterModal] = useState(true);

  useEffect(() => {
    if (!!productReducer.success) {
      if (localStorage.getItem("category") === category) {
        setProducts((pre) => [...productReducer?.products]);

        setPage(page + 1);
        if (productReducer?.products?.length < 20) {
          setHasMore(false);
        }
      } else {
        setProducts([]);
        setPage(1);
        setHasMore(true);
      }
      disptach(resetproductReducer());
    }
  }, [productReducer]);

  useEffect(() => {
    const userdata = JSON.parse(localStorage.getItem("user"));
    localStorage.setItem("category", category);
    setProducts([]);
    setHasMore(true);
    setPage(1);
    disptach(
      fetchProducts({
        category: category,
        page: 1,
        userId: userdata?.id,
        metal_type:
          category === "yellow-gold-engagement-rings" ||
          category === "yellow-gold-wedding-bands"
            ? 1
            : category === "rose-gold-engagement-rings" ||
              category === "rose-gold-wedding-bands"
            ? 2
            : null,
      })
    );
  }, [category]);

  const [countryCurrency, setCountryCurrency] = useState("INR");

  useEffect(() => {
    setCountryCurrency(localStorage.getItem("currency"));
  }, []);

  const fetchData = async () => {
    const userdata = await JSON.parse(localStorage.getItem("user"));
    if (productReducer.products.length === 20) {
      await setTimeout(() => {
        disptach(
          fetchProducts({
            category: category,
            page: page,
            userId: userdata?.id,
            metal_type:
              category === "yellow-gold-engagement-rings" ||
              category === "yellow-gold-wedding-bands"
                ? 1
                : category === "rose-gold-engagement-rings" ||
                  category === "rose-gold-wedding-bands"
                ? 2
                : null,
          })
        );
      }, 1500);
    }
  };

  const onAddToFavourite = async (data) => {
    // if (!isLogin()) return toast.error("Oops, Please login to add to wishlist");
    const userdata = JSON.parse(localStorage.getItem("user"));

    const cat = localStorage.getItem("category");
    // eslint-disable-next-line no-restricted-globals
    alert("Product has been added to your wishlist.");

    // await dispatch(addToFavourite(produtInfo?.[selectedColor?.key]?.id));
    await disptach(addToFavourite(data));
    await disptach(getFavouriteList());
    await disptach(
      fetchProducts({
        category: cat,
        page: page,
        userId: userdata?.id,
        metal_type:
          category === "yellow-gold-engagement-rings" ||
          category === "yellow-gold-wedding-bands"
            ? 1
            : category === "rose-gold-engagement-rings" ||
              category === "rose-gold-wedding-bands"
            ? 2
            : null,
      })
    );
    // await setProducts((pre) => {
    //   return [...pre, ...productReducer?.products];
    // });
  };

  const handleRemove = async (id) => {
    // if (!isLogin())
    //   return toast.error("Oops, Please login to Remove to wishlist");
    const userdata = JSON.parse(localStorage.getItem("user"));
    const cat = localStorage.getItem("category");
    const conf = confirm(
      "Are you sure you want to remove from your wishlist item?"
    );
    if (!conf) return;
    await disptach(removeFavoriteList(id));
    await disptach(getFavouriteList());
    await disptach(
      fetchProducts({
        category: cat,
        page: 1,
        userId: userdata?.id,
        metal_type:
          category === "yellow-gold-engagement-rings" ||
          category === "yellow-gold-wedding-bands"
            ? 1
            : category === "rose-gold-engagement-rings" ||
              category === "rose-gold-wedding-bands"
            ? 2
            : null,
      })
    );
  };

  return (
    <>
      <div className="container my-3">
        <Breadcrumb>
          <LinkContainer to="/" className="link-breadcrumb">
            <Breadcrumb.Item>Home</Breadcrumb.Item>
          </LinkContainer>

          <Breadcrumb.Item active>
            {convertToTitleCase(category)}
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="page-title">
        <div
          style={{
            color: "#e77e50",
            fontSize: "22px",
            fontWeight: 700,
          }}
        >
          {slug}
        </div>
        {/* <h5>Home - Rings Fashion - Rings</h5> */}
      </div>
      <NameContextProvider>
        {showFilterModal && (
          <FilterModal
            show={showFilterModal}
            onHide={() => setShowFilterModal(false)}
            activeCollection="products"
          />
        )}
        {/* Begin:: Product Listing */}
        <div>
          <InfiniteScroll
            dataLength={products.length}
            next={fetchData}
            hasMore={
              hasMore &&
              category !== "yellow-gold-engagement-rings" &&
              category !== "rose-gold-engagement-rings" &&
              category === "yellow-gold-wedding-bands" &&
              category === "rose-gold-wedding-bands"
            }
            loader={<h4 className="text-center">Loading...</h4>}
            endMessage={<></>}
            style={{
              overflow: "hidden",
            }}
          >
            <CustomContainer className="product-container">
              <div className="row gx-4 product-listing listingpage-container">
                {!products && <Loader />}

                {products?.length
                  ? products?.map((item) => {
                      // const item_detail = JSON?.parse(item?.item_detail);
                      const produtInfo = item?.product_variation
                        ? transformProductData(item, countryCurrency)
                        : newTransformProductData(item, countryCurrency);

                      return (
                        <>
                          <ProductCard
                            produtInfo={produtInfo}
                            // {...produtInfo}
                            key={item.id}
                            item={item}
                            onAddToFavourite={onAddToFavourite}
                            handleRemove={handleRemove}
                            defaultSelcted={
                              category === "yellow-gold-engagement-rings"
                                ? {
                                    color:
                                      "linear-gradient(227deg, #d9b434 0%, #fef6d2 37%, #e0c962 100%)",
                                    key: "yellow",
                                  }
                                : category === "rose-gold-engagement-rings"
                                ? {
                                    color:
                                      "linear-gradient(227deg, #ebcab9 0%, #fdefe8 37%, #e6bea8 100%)",
                                    key: "rose",
                                  }
                                : category === "yellow-gold-wedding-bands"
                                ? {
                                    color:
                                      "linear-gradient(227deg, #d9b434 0%, #fef6d2 37%, #e0c962 100%)",
                                    key: "yellow",
                                  }
                                : category === "rose-gold-wedding-bands"
                                ? {
                                    color:
                                      "linear-gradient(227deg, #ebcab9 0%, #fdefe8 37%, #e6bea8 100%)",
                                    key: "rose",
                                  }
                                : null
                            }
                          />
                        </>
                      );
                    })
                  : !hasMore && (
                      <div>
                        <p
                          style={{
                            color: "#e77e5a0",
                            fontSize: "26px",
                            fontWeight: 700,
                          }}
                          className=" text-center "
                        >
                          COMING SOON
                        </p>
                        <p className=" text-center mb-5">
                          We are bringing products to {slug} very soon,
                          meanwhile explore these amazing products.{" "}
                        </p>
                        <CategoryComingSoonProducts />
                      </div>
                    )}
              </div>
            </CustomContainer>
          </InfiniteScroll>
        </div>
      </NameContextProvider>
    </>
  );
};

export default ProductList;

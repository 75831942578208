import axios from "axios";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { baseUrl } from "../../constants/urls";
import { socialIcons } from "../../constants/utils";
import { imageURL } from "../../images";
import Chatbot from "./Chatbot";
// import Chatbott from "./Chatbott";

const Footer = ({ style }) => {
  const [email, setEmail] = useState("");
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const navigate = useNavigate();
  const handleSubscribe = async () => {
    try {
      const res = await axios.post(`${baseUrl}/api/subscribe-newsletter`, {
        email: email,
      });
      toast.success(res?.data?.data?.message);
      setEmail("");
    } catch (error) {
      console.log(error);
      setIsError(true);
      toast.error(error?.response?.data?.error?.[0]?.message);
      setErrorMsg("Enter Valid Email");
    }
  };
  return (
    <footer className="container-fluid pb-0 pt-5" style={!!style ? style : {}}>
      <div className="container footer-detail pt-3 pb-1">
        <div className="row">
          <div className="col-lg-3 col-12">
            <div className="d-lg-flex  d-xl-block justify-content-between flex-md-column mb-4 mb-lg-0">
              <img
                onClick={() => {
                  navigate("/");
                }}
                className="object-fit-cover img-fluid mx-auto me-sm-auto mx-lg-auto d-block mb-xl-4 logo"
                src={imageURL.logo}
                alt="Logo"
              />
              {/* <p className="d-none d-lg-block">Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p> */}
              <div className="footer-social">
                {socialIcons?.map((item, index) => (
                  <Link target="_blank" to={item?.link} key={index}>
                    {item?.icon}
                  </Link>
                ))}
                {/* <Link
                  target="_blank"
                  to={"https://www.facebook.com/andorluxuryllp"}
                >
                  <i className="fa-brands fa-facebook-f"></i>
                </Link>
                <Link
                  target="_blank"
                  to={"https://www.instagram.com/andorluxury"}
                >
                  <i className="fa-brands fa-instagram"></i>
                </Link>
                <Link
                  target="_blank"
                  to={"https://in.pinterest.com/andorluxury"}
                >
                  <i className="fa-brands fa-pinterest"></i>
                </Link>
                <Link
                  target="_blank"
                  to={"https://www.youtube.com/@AndorLuxury"}
                >
                  <i className="fa-brands fa-youtube"></i>
                </Link>
                <Link
                  target="_blank"
                  to={"https://www.tiktok.com/@andorluxury.com"}
                >
                  <i className="fa-brands fa-tiktok"></i>
                </Link> */}
              </div>
            </div>
          </div>
          <div className="col-lg-9 ">
            <div className="row">
              <div className="col-xxl-6 col-lg-12 col-12 row">
                <div className=" col-xxl-6 col-md-4 col-lg-4 col-6">
                  <h4 className="text-primary">Company</h4>
                  <ul className="footer-links">
                    <li>
                      <Link to="/about">Our Story</Link>
                    </li>
                    {/* <li>
                    <Link to="">Our Materials</Link>
                  </li>
                  <li>
                    <Link to="">Career</Link>
                  </li> */}
                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                    <li>
                      <Link to="/buying-guide/ring-size/">Ring Size Guide</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-xxl-6 col-md-4 col-lg-4 col-6">
                  <h4 className="text-primary">Supports</h4>
                  <ul className="footer-links">
                    <li>
                      <Link to={"/privacy-policy"}>Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to={"/terms-and-conditions"}>
                        Terms and Conditions
                      </Link>
                    </li>
                    <li>
                      <Link to={"/return"}>Returns Policy</Link>
                    </li>
                    <li>
                      <Link to={"/shipping-policy"}>Shipping Policy</Link>
                    </li>
                    <li>
                      <Link to={"/our-policy"}>Our Policy</Link>
                    </li>
                  </ul>
                </div>
                <div className=" col-xxl-12 col-md-4 col-lg-3 col-12">
                  <h4 className="text-primary">Contact</h4>
                  <ul className="footer-links">
                    {/* <li>+(91) 98243 99133</li> */}
                    {/* <li>215 Hinton Street united State</li> */}
                    {/* <li>info@andorluxury.com</li> */}
                    <Link
                      className="text-black mx-2"
                      to="mailto:info@andorluxury.com"
                    >
                      <li>info@andorluxury.com</li>
                    </Link>
                  </ul>
                </div>
              </div>

              <div className="col-xxl-6 col-lg-8 col-12">
                <h4 className="text-primary">
                  Get News & Update to Your Inbox
                </h4>
                <ul className="footer-links">
                  {/* <li>+(91) 98243 99133</li> */}
                  {/* <li>215 Hinton Street united State</li> */}
                  <li>
                    {" "}
                    <div
                      className="subscribe-input"
                      // data-aos="fade-up"
                    >
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email Address"
                        value={email}
                        onChange={(e) => {
                          setIsError(false);
                          setErrorMsg("");
                          setEmail(e.target.value);
                        }}
                      />
                      <Button
                        className="btn-theme bg-primary border-0 text-white"
                        onClick={handleSubscribe}
                      >
                        Subscribe
                      </Button>
                      {isError && (
                        <div style={{ color: "red", fontSize: "16px" }}>
                          {errorMsg}
                        </div>
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr className="mt-0" style={{ marginBottom: "1px" }} />
        <p className="copyright text-center mb-0">
          &copy; 2025 Andorluxury.com
        </p>
      </div>
      {/* <Chatbott /> */}
      <Chatbot />
    </footer>
  );
};

export default Footer;

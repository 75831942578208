import React, { useState, useEffect, useRef } from "react";
import { MdSend } from "react-icons/md";
import { FaCommentDots } from "react-icons/fa";
import { RiArrowDropDownLine } from "react-icons/ri";

const Chatbot = () => {
  const [open, setOpen] = useState(false);
  const [messages, setMessages] = useState([
    {
      type: "bot",
      text: "Hello, I'm Andor Chatbot. I am here to answer your queries related to Andor Luxury.",
      time: new Date().toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      }),
    },
  ]);
  const [input, setInput] = useState("");
  const [showFAQ, setShowFAQ] = useState(true);
  const [suggestions, setSuggestions] = useState([]);
  const chatMessagesRef = useRef(null);

  const toggleChat = () => {
    setOpen(!open);
  };

  const addMessage = (text, type, suggestions = []) => {
    const time = new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    setMessages((prev) => [...prev, { type, text, time }]);
    setSuggestions(suggestions);

    setTimeout(() => {
      chatMessagesRef.current?.scrollTo({
        top: chatMessagesRef.current.scrollHeight,
        behavior: "smooth",
      });
    }, 100);
  };

  const sendMessage = async (message) => {
    if (!message.trim()) return;

    addMessage(message, "user");
    setInput("");
    setShowFAQ(false);
    addMessage("Chatbot is typing...", "bot");

    try {
      const response = await fetch("https://chat.jaisofttech.com/chat", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "API-KEY": "5fd478b2-962d-4052-82a2-fd6e72ebbfb7",
        },
        body: JSON.stringify({ query: message }),
      });
      const data = await response.json();

      setMessages((prev) =>
        prev.filter((msg) => msg.text !== "Chatbot is typing...")
      );
      addMessage(data.response, "bot", data.suggestions || []);
    } catch (error) {
      setMessages((prev) =>
        prev.filter((msg) => msg.text !== "Chatbot is typing...")
      );
      addMessage("Sorry, an error occurred.", "bot");
    }
  };

  const handleFAQ = (question) => {
    sendMessage(question);
  };

  return (
    <>
      <button className="chat-toggle-button" onClick={toggleChat}>
        <FaCommentDots className="chat-toggle-icon" /> Chat
      </button>

      <div className={`chat-container ${open ? "open" : ""}`}>
        <div className="chat-header" onClick={toggleChat}>
          Welcome to ANDOR Luxury
          <button className="close-button">
            <RiArrowDropDownLine />
          </button>
        </div>

        <div className="chat-messages" ref={chatMessagesRef}>
          {messages.map((msg, index) => (
            <div key={index} className={`message ${msg.type}-message`}>
              {msg.text}
              <span className="timestamp">{msg.time}</span>
            </div>
          ))}

          {suggestions.length > 0 && (
            <div className="suggestion-container">
              {suggestions.map((suggestion, index) => (
                <button
                  key={index}
                  className="suggestion-button"
                  onClick={() => sendMessage(suggestion)}
                >
                  {suggestion}
                </button>
              ))}
            </div>
          )}

          {showFAQ && (
            <div className="faq-buttons">
              <button onClick={() => handleFAQ("Materials Used?")}>
                Materials Used?
              </button>
              <button onClick={() => handleFAQ("Customization?")}>
                Customization?
              </button>
              <button onClick={() => handleFAQ("Return policy?")}>
                Return policy?
              </button>
              <button onClick={() => handleFAQ("Lifetime warranty?")}>
                Lifetime warranty?
              </button>
            </div>
          )}
        </div>

        <div className="chat-input">
          <input
            type="text"
            placeholder="Type a message..."
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && sendMessage(input)}
          />
          <button onClick={() => sendMessage(input)}>
            <MdSend />
          </button>
        </div>
        <div className="message-noti">
          <span>Powered by</span> <strong>jAI SoftTech</strong>
        </div>
      </div>
    </>
  );
};

export default Chatbot;

/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Loader from "../UI/Loader";
import ProductCard from "../product/ProductCard";
import { useDispatch, useSelector } from "react-redux";
import { getJewelryCollectionAction } from "../../features/Collections/collectionSlice";
import {
  newTransformProductData,
  transformProductData,
} from "../../constants/function";
import {
  addToFavourite,
  getFavouriteList,
  removeFavoriteList,
} from "../../features/cart/cartSlice";
import { CustomContainer } from "../common/global-display";
import FilterModal from "../product/FilterModal";
// import { colorsVariant } from "../../constants/utils";
import CategoryComingSoonProducts from "../CategoryCominSoonProduct";

function JewelryCollection({ isShown, setIsShown }) {
  //localStorage.setItem("category", "");
  const dispatch = useDispatch();

  // Connect both reducers
  const reducer = useSelector((state) => state?.collectionsReducer?.jewelry);
  const [countryCurrency, setCountryCurrency] = useState("INR");
  const [listOfCollection, setListOfCollection] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  // Fetch country currency from localStorage
  useEffect(() => {
    setCountryCurrency(localStorage.getItem("currency"));
  }, []);

  useEffect(() => {
    dispatch(getJewelryCollectionAction());
  }, [dispatch]);

  // Update collection data once fetched
  useEffect(() => {
    if (reducer?.success) {
      const data = reducer?.data?.data;
      setListOfCollection(data);
      setHasMore(data.length > 0);
    }
  }, [reducer]);

  const onAddToFavourite = async (data) => {
    // if (!isLogin()) return toast.error("Oops, Please login to add to wishlist");

    // eslint-disable-next-line no-restricted-globals
    alert("Product has been added to your wishlist.");
    // await dispatch(addToFavourite(produtInfo?.[selectedColor?.key]?.id));
    await dispatch(addToFavourite(data));
    await dispatch(getFavouriteList());
    await dispatch(getJewelryCollectionAction());
  };

  const handleRemove = async (id) => {
    // if (!isLogin())
    //   return toast.error("Oops, Please login to Remove to wishlist");

    const conf = confirm(
      "Are you sure you want to remove from your wishlist item?"
    );
    if (!conf) return;
    await dispatch(removeFavoriteList(id));
    await dispatch(getFavouriteList());
    dispatch(getJewelryCollectionAction());
  };

  return (
    <>
      <div className="container my-3">
        <Breadcrumb>
          <LinkContainer to="/" className="link-breadcrumb">
            <Breadcrumb.Item>Home</Breadcrumb.Item>
          </LinkContainer>
          <Breadcrumb.Item active>Jewelry Collection</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <div className="page-title">
        <div
          style={{
            color: "#e77e50",
            fontSize: "22px",
            fontWeight: 700,
          }}
        >
          Jewelry Collection
        </div>
      </div>

      <FilterModal
        isOpen={isShown}
        onClose={() => setIsShown(false)}
        activeCollection="jewelry"
      />

      <CustomContainer className="mt-0 mb-0 product-container">
        <div className="row gx-4 product-listing listingpage-container">
          {reducer?.loading ? (
            <Loader />
          ) : listOfCollection.length > 0 ? (
            listOfCollection.map((item) => {
              // const defaultKey = item?.rose
              //   ? "rose"
              //   : item?.yellow
              //   ? "yellow"
              //   : "white"; // Default to white if no color is available

              // Ensure color exists in colorsVariant to avoid undefined errors
              // const defaultColor =
              //   colorsVariant[defaultKey] || colorsVariant["white"];

              const produtInfo = item?.product_variation
                ? transformProductData(item, countryCurrency)
                : newTransformProductData(item, countryCurrency);

              return (
                <ProductCard
                  produtInfo={produtInfo}
                  key={item.id}
                  item={item}
                  onAddToFavourite={onAddToFavourite}
                  handleRemove={handleRemove}
                  // defaultSelected={{
                  //   key: defaultKey,
                  //   color: defaultColor,
                  // }}
                />
              );
            })
          ) : (
            !hasMore && (
              <div>
                <p
                  style={{
                    color: "#e77e5a0",
                    fontSize: "26px",
                    fontWeight: 700,
                  }}
                  className="text-center"
                >
                  COMING SOON
                </p>
                <p className="text-center mb-5">
                  We are bringing products to very soon, meanwhile explore these
                  amazing products.
                </p>
                <CategoryComingSoonProducts />
              </div>
            )
          )}
        </div>
      </CustomContainer>
    </>
  );
}

export default JewelryCollection;

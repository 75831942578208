import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getPlacedOrders } from "../../features/productsList/orderSlice";
// import { LiaRubleSignSolid, LiaRupeeSignSolid } from "react-icons/lia";
import moment from "moment";
import DataTable from "react-data-table-component";
import { HiCheckCircle } from "react-icons/hi";
import { useLocation, useNavigate } from "react-router";
import { currencyIcon } from "../../constants/function";
import { orderStatus } from "../../constants/utils";
import OrderModal from "../EditProfile/order-modal";
import "../Home/Home.css";
import Loader from "../UI/Loader";
// import Swal from "sweetalert2";
// import withReactContent from "sweetalert2-react-content";

// const MySwal = withReactContent(Swal);

const Orders = ({ activeKey }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const orderedItems = useSelector((state) => state.order.order);
  const [showInitialMessage, setShowInitialMessage] = useState(false);
  const columns = [
    {
      name: "PRODUCT IMAGE",
      selector: (row) => row.productImage,
      cell: (row) => (
        <img
          src={row.productImage}
          alt={row.productName}
          style={{ width: "50px", height: "auto", padding: "5px" }}
        />
      ),
    },
    {
      name: "ORDER NO.",
      selector: (row) => row.orderNumber,
    },
    {
      name: "ORDER DATE",
      selector: (row) => row.orderDate,
    },
    {
      name: "TOTAL AMT",
      selector: (row) => row.totalAmount,
    },
    {
      name: "PAYMENT STATUS",
      selector: (row) => row.paymentStatus,
    },
    {
      name: "STATUS",
      selector: (row) => row.status,
    },
    // {
    //   id: "actions",
    //   label: "Actions",
    //   selector: (row) => (
    //     <Button variant="primary" onClick={() => handleViewOrder(row)}>
    //       View
    //     </Button>
    //   ),
    // },
    // {
    //   name: "ACTION",
    //   selector: (row) => row.action,
    //   // cell: (row) => (
    //   //   <button onClick={() => handleAction(row.orderNumber)}>Action</button>
    //   // ),
    // },
    // {
    //   name: "RETURN",
    //   selector: (row) => row.return,
    //   // cell: (row) => (
    //   //   <button onClick={() => handleReturn(row.orderNumber)}>Return</button>
    //   // ),
    // },
  ];

  const [details, setDetails] = useState([]);

  useEffect(() => {
    if (!location?.search?.includes("order_id")) return;
    if (location?.search?.includes("order_id"))
      return setShowInitialMessage((pr) => !pr);
  }, [location]);
  const [showModal, setShowModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const handleViewOrder = (order) => {
    setSelectedOrder(order);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const order_items = orderedItems.map((item) => {
      // console.log(orderStatus[item?.status]);
      // console.log(item)
      return {
        ...item,
        productImage:
          item?.products[0]?.product?.featured_image[0]?.original_url,

        orderDate:
          moment(item?.updated_at)?.format("DD/MMM/YYYY hh:mm A") ??
          "01/Jan/2023 0:00 PM",
        totalAmount: `${currencyIcon(
          item?.currency ?? localStorage.getItem("currency"),
        )} ${item?.amount}`,
        paymentStatus: item?.payment_id ? "Paid" : "Pending",
        status: orderStatus[item?.status] ?? "-",
        action: "View",
        return: "Eligible",
        actions: { ...item },
        orderNumber: (
          // <p
          //   className="cursor-pointer"
          //   // onClick={() => handleViewOrder(item)}
          // >{`#ORD${padZeros(item?.id, 6)}`}</p>
          <p className="cursor-pointer m-0">{`${item?.code}`}</p>
        ),
        rowData: item,
      };
    });

    setDetails(order_items);
  }, [orderedItems]);

  const [isFirstTime, setIsFirstTime] = useState(true);
  useEffect(() => {
    if (isFirstTime) {
      dispatch(getPlacedOrders());
      setIsFirstTime(false);
    }
  }, [activeKey]);

  useEffect(() => {
    if (!location?.search?.includes("order_id")) return;
    const timer = setTimeout(() => {
      navigate("/edit-profile", { state: { eventKey: "orders" } });
      return setShowInitialMessage(false);
    }, 5000);

    // Cleanup the timer
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="mt-5">
      {/* <h2>Order History</h2> */}

      <Row className="justify-content-center">
        <Col sm={12} className="my-3">
          {/* https://react-data-table-component.netlify.app/?path=/story/columns-delayed--delayed */}

          {showInitialMessage ? (
            <div className="d-flex flex-column align-items-center">
              <HiCheckCircle
                className="animated-icon"
                color="green"
                size={80}
              />
              <h2>Your Order is Confirmed!</h2>
              <p>
                Thank you for your purchase. Your order ID is{" "}
                <strong>#123456</strong>.
              </p>
              <p>
                We appreciate your business and will notify you once your order
                is on its way.
              </p>
            </div>
          ) : (
            <DataTable
              // title="My Orders"
              columns={columns}
              data={details}
              defaultSortFieldId={1}
              pagination
              highlightOnHover
              noDataComponent={
                <div className="text-center py-2">
                <h3>No orders found. </h3>
                <p className="no-data-text">
                Please add an order!
                </p>
              </div>
              }
            
              onRowClicked={(v) => {
                handleViewOrder(v?.rowData);
              }}
            />
          )}

          <div className="">
            <Row>
              <Col className="text-start">
                <div
                  variant="link"
                  className="text-decoration-none fs-5"
                  style={{ cursor: "pointer", color: "#e77e50" }}
                  onClick={() => {
                    navigate("/profile");
                  }}
                >
                  &lt; Back
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <OrderModal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        selectedOrder={selectedOrder}
      />
    </div>
  );
};

export default Orders;

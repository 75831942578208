/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from "react";
import Loader from "../UI/Loader";
import ProductCard from "../product/ProductCard";
import { useDispatch, useSelector } from "react-redux";
import { getWeddingCollectionAction } from "../../features/Collections/collectionSlice";
import {
  newTransformProductData,
  transformProductData,
} from "../../constants/function";
import {
  addToFavourite,
  getFavouriteList,
  removeFavoriteList,
} from "../../features/cart/cartSlice";
import { CustomContainer } from "../common/global-display";
import GlobalBreadcrumb from "../common/global-breadcrumb";
import FilterModal from "../product/FilterModal";
import CategoryComingSoonProducts from "../CategoryCominSoonProduct";
import { colorsVariant } from "../../constants/utils";

function WeddingCollection({ isShown, setIsShown }) {
  const dispatch = useDispatch();
  const reducer = useSelector((state) => state?.collectionsReducer?.wedding);

  const [countryCurrency, setCountryCurrency] = useState("INR");

  useEffect(() => {
    setCountryCurrency(localStorage.getItem("currency"));
  }, []);

  useEffect(() => {
    dispatch(getWeddingCollectionAction());
  }, [dispatch]);

  const [listOfCollection, setListOfCollection] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    if (reducer?.success) {
      const data = reducer?.data?.data;
      setListOfCollection(data);
      setHasMore(data.length > 0);
    }
  }, [reducer]);

  const onAddToFavourite = async (data) => {
    // if (!isLogin()) return toast.error("Oops, Please login to add to wishlist");

    // eslint-disable-next-line no-restricted-globals
    alert("Product has been added to your wishlist.");
    // await dispatch(addToFavourite(produtInfo?.[selectedColor?.key]?.id));
    await dispatch(addToFavourite(data));
    await dispatch(getFavouriteList());
    await dispatch(getWeddingCollectionAction());
  };

  const handleRemove = async (id) => {
    // if (!isLogin())
    //   return toast.error("Oops, Please login to Remove to wishlist");

    const conf = confirm(
      "Are you sure you want to remove from your wishlist item?"
    );
    if (!conf) return;
    await dispatch(removeFavoriteList(id));
    await dispatch(getFavouriteList());
    dispatch(getWeddingCollectionAction());
  };

  return (
    <>
      <GlobalBreadcrumb subTitle="Wedding Collection" />

      <h3 className="text-center text-primary fw-bold">Wedding Collection</h3>

      <FilterModal
        isOpen={isShown}
        onClose={() => setIsShown(false)}
        activeCollection="wedding"
      />

      <CustomContainer className="mt-0 mb-0 product-container">
        <div className="row gx-4 product-listing listingpage-container">
          {reducer?.loading ? (
            <Loader />
          ) : listOfCollection.length > 0 ? (
            listOfCollection.map((item) => {
              const produtInfo = item?.product_variation
                ? transformProductData(item, countryCurrency)
                : newTransformProductData(item, countryCurrency);

              return (
                <ProductCard
                  produtInfo={produtInfo}
                  key={item.id}
                  item={item}
                  onAddToFavourite={onAddToFavourite}
                  handleRemove={handleRemove}
                  defaultSelcted={
                    item?.product_variation?.length > 0
                      ? {
                          key: item.product_variation[0]?.key || "white",
                          color:
                            colorsVariant?.[item.product_variation[0]?.key] ||
                            colorsVariant?.["white"],
                        }
                      : { key: "white", color: colorsVariant?.["white"] }
                  }
                />
              );
            })
          ) : (
            !hasMore && (
              <div>
                <p
                  style={{
                    color: "#e77e5a0",
                    fontSize: "26px",
                    fontWeight: 700,
                  }}
                  className="text-center"
                >
                  COMING SOON
                </p>
                <p className="text-center mb-5">
                  We are bringing products to very soon, meanwhile explore these
                  amazing products.
                </p>
                <CategoryComingSoonProducts />
              </div>
            )
          )}
        </div>
      </CustomContainer>
    </>
  );
}

export default WeddingCollection;

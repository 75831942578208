import classNames from "classnames";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Breadcrumb, Col, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { useNavigate } from "react-router-dom";
import { userSingup } from "../../features/user/singupSilce";
import { monthDateList } from "../../helper/constant";
import { registerUserdetailsSchema } from "../../Validation/Schema/UserDetailsSchema";
import GlobalTitle from "../common/global-title";
import { CustomContainer } from "../common/global-display";

const SignUp = () => {
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDob] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [mail, setMail] = useState("");
  const [terms, setTerms] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const user = {
    first_name: firstName,
    last_name: lastName,
    dob: dob,
    email: mail,
    mobile: phone,
    country_code: "+91",
    password: password,
  };

  const navigate = useNavigate();
  const initialValue = {
    email: "",
    first_name: "",
    last_name: "",
    mobile: "",
    birthday_date: "",
    birthday_month: "",
    password: "",
    isCheck: false,
  };
  const [details, setDetails] = useState(initialValue);

  //   Effect for creat account button state

  useEffect(() => {
    if (
      firstName.trim().length > 0 &&
      lastName.trim().length > 0 &&
      dob.trim().length > 0 &&
      phone.trim().length >= 10 &&
      password.trim().length > 1 &&
      terms === true
    ) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [firstName, lastName, phone, password, terms, mail, isButtonDisabled]);

  const useSignUpReducer = useSelector((state) => state.singup);

  useEffect(() => {
    // console.log(useSignUpReducer);
    if (useSignUpReducer.success) {
      toast.success("Create user profile successfull!!");
      navigate("/");
    } else if (useSignUpReducer.error) {
      // toast.error("Something went wrong");
    }
  }, [useSignUpReducer]);

  return (
    <>
      <div className="container my-3">
        <Breadcrumb>
          <LinkContainer to="/" className="link-breadcrumb">
            <Breadcrumb.Item>Home</Breadcrumb.Item>
          </LinkContainer>
          <Breadcrumb.Item active>Create User</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <CustomContainer>
        <Col lg={8} xl={7} className="mx-auto">
          <GlobalTitle title="Create Your Account" />
          {/* <h2 className="text-center fw-bold mb-5">Create Your Account</h2> */}
          <div className="signup-info">
            <h5>Create an account with us and you will be able to:</h5>
            <p className="mb-0">
              <i className=" text-primary me-2 fa-solid fa-clock-rotate-left"></i>{" "}
              Easy access to order history, track order, and more
            </p>
            <p className="mb-0">
              <i className=" text-primary me-2 fa-solid fa-bag-shopping"></i>{" "}
              Faster checkout with stored billing address information
            </p>
            <p className="mb-0">
              <i className=" text-primary me-2 fa-solid fa-gift"></i> Exclusive
              offers, discounts, and shipping upgrades
            </p>
          </div>

          <Formik
            initialValues={details}
            enableReinitialize={true}
            validationSchema={registerUserdetailsSchema}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              // console.log(values);
              // const payload = values;
              // delete payload.isCheck;

              // delete values.isCheck;
              await dispatch(userSingup(values));
              // await createAccount(values);
            }}
          >
            {({ handleChange, errors, touched, values }) => {
              // console.log(values, "values");
              return (
                <Form className="auth-form px-0">
                  <Row className=" g-0 g-md-5">
                    <Col md={12} className="mb-3">
                      <label htmlFor="" className="form-label">
                        First Name *
                      </label>
                      <input
                        name="first_name"
                        className={classNames("form-control", {
                          "error-border":
                            errors.first_name && touched.first_name,
                        })}
                        onChange={handleChange}
                        placeholder="First Name"
                        value={values.first_name}
                      />
                      {errors.first_name && touched.first_name && (
                        <div className="text-danger small">
                          {errors.first_name}
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row className=" g-0 g-md-5">
                    <Col md={12} className="mb-3">
                      <label htmlFor="" className="form-label">
                        Last Name *
                      </label>
                      <input
                        name="last_name"
                        className={classNames("form-control", {
                          "error-border": errors.last_name && touched.last_name,
                        })}
                        onChange={handleChange}
                        placeholder="Last Name"
                        value={values.last_name}
                      />
                      {errors.last_name && touched.last_name && (
                        <div className="text-danger small">
                          {errors.last_name}
                        </div>
                      )}
                    </Col>
                  </Row>
                  {/* <Row className=" g-0 g-md-5">
                    <Col md={12} className="mb-3">
                      <label htmlFor="" className="form-label">
                        Your Birthday
                      </label>
                      <Row className=" g-0 g-md-5">
                        <Col md={6} className="mb-3">
                          <select
                            value={values.birthday_month}
                            onChange={handleChange}
                            name="birthday_month"
                            className={classNames("form-select", {
                              "error-border":
                                errors.birthday_month && touched.birthday_month,
                            })}
                          >
                            <option value={""} style={{ cursor: "pointer" }}>
                              Month
                            </option>
                            {monthDateList.map((item, index) => {
                              return (
                                <option
                                  value={item.month}
                                  key={index}
                                  style={{ cursor: "pointer" }}
                                >
                                  {item.month}
                                </option>
                              );
                            })}
                          </select>
                          {errors.birthday_month && touched.birthday_month && (
                            <div className="text-danger small">
                              {errors.birthday_month}
                            </div>
                          )}
                        </Col>
                        <Col md={6} className="mb-0 mb-md-3">
                          <select
                            value={values.birthday_date}
                            onChange={handleChange}
                            className={classNames("form-select", {
                              "error-border":
                                errors.birthday_date && touched.birthday_date,
                            })}
                            name="birthday_date"
                          >
                            <option value={""} style={{ cursor: "pointer" }}>
                              Date
                            </option>
                            {monthDateList
                              ?.find(
                                (item) => item.month == values.birthday_month,
                              )
                              ?.date.map((item, index) => {
                                return (
                                  <option
                                    value={item}
                                    key={index}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {item}
                                  </option>
                                );
                              })}
                          </select>
                          {errors.birthday_date && touched.birthday_date && (
                            <div className="text-danger small">
                              {errors.birthday_date}
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row> */}
                  <Row className=" g-0 g-md-5">
                    <Col md={12} className="mb-3">
                      <label htmlFor="" className="form-label">
                        Email *
                      </label>
                      <input
                        name="email"
                        className={classNames("form-control", {
                          "error-border": errors.email && touched.email,
                        })}
                        onChange={handleChange}
                        placeholder="Email"
                        value={values.email}
                      />
                      {errors.email && touched.email && (
                        <div className="text-danger small">{errors.email}</div>
                      )}
                    </Col>
                  </Row>
                  <Row className=" g-0 g-md-5">
                    <Col md={12} className="mb-3">
                      <label htmlFor="" className="form-label">
                        mobile
                      </label>
                      <input
                        name="mobile"
                        className={classNames("form-control", {
                          "error-border": errors.mobile && touched.mobile,
                        })}
                        onChange={handleChange}
                        placeholder="mobile"
                        value={values.mobile}
                      />
                      {errors.mobile && touched.mobile && (
                        <div className="text-danger small">{errors.mobile}</div>
                      )}
                    </Col>
                  </Row>
                  <Row className=" g-0 g-md-5">
                    <Col md={12} className="mb-3">
                      <div className="d-flex gap-3">
                        <input
                          name="isCheck"
                          type="checkbox"
                          className={classNames("form-check-input", {
                            "error-border": errors.isCheck && touched.isCheck,
                          })}
                          onChange={handleChange}
                          checked={values.isCheck}
                          id="isCheck"
                        />

                        <label
                          className="form-check-label small text-muted ps-2"
                          htmlFor="isCheck"
                        >
                          By checking this box, you agree to receive automated
                          personalized text messages (e.g. cart reminders,
                          shipping updates, and tracking info) from Andor at the
                          mobile number provided. Consent is not a condition of
                          any purchase. Reply HELP for help and STOP to cancel.
                          Msg frequency varies. Msg & data rates may apply.{" "}
                          <a href={"/privacy"}>View</a> Terms &{" "}
                          <a href={"/privacy"}>Privacy</a>
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <Row className=" g-0 g-md-5">
                    <Col md={12} className="mb-3">
                      <label htmlFor="" className="form-label">
                        Password
                      </label>
                      <input
                        name="password"
                        className={classNames("form-control", {
                          "error-border": errors.password && touched.password,
                        })}
                        onChange={handleChange}
                        placeholder="Password"
                        value={values.password}
                      />
                      {errors.password && touched.password && (
                        <div className="text-danger small">
                          {errors.password}
                        </div>
                      )}
                    </Col>
                  </Row>
                  <div className="text-center mt-5">
                    <button
                      className="btn btn-secondary btn-theme px-5"
                      type="submit"
                    >
                      Create Account
                    </button>
                  </div>

                  {/* 
                  <div className="">
                    <Row>
                      <Col className="text-start">
                        <div
                          variant="link"
                          className="text-decoration-none fs-5"
                          style={{ cursor: "pointer", color: "#e77e50" }}
                          onClick={() => {
                            navigate("/profile");
                          }}
                        >
                          &lt; Back
                        </div>
                      </Col>
                      <Col className="text-end">
                        <Button
                          variant="primary"
                          className="px-4 py-2"
                          type="submit"
                          // disabled={isSubmitting}
                          // onClick={handleSubmit}
                        >
                          UPDATE
                        </Button>
                      </Col>
                    </Row>
                  </div> */}
                </Form>
              );
            }}
          </Formik>
        </Col>
      </CustomContainer>
    </>
  );
};

export default SignUp;

import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
// import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../element/Header";
import Footer from "../element/Footer";
import axios from "axios";
import { getSeo } from "../../constants/urls";
import { Helmet } from "react-helmet";
// import { Helmet } from "react-helmet";
const meta_title = "Andor Luxury - Lab Grown Diamond Jewelry & Ethical Engagement Rings";
const meta_desc = "Andor Luxury offers sustainable, lab-grown diamond jewelry, including engagement rings, necklaces, and more. Discover ethical luxury with conflict-free diamonds.";
const AppLayout = () => {
  const location = useLocation();
  const [seoData, setSeoData] = useState({
    meta_title: meta_title,
    meta_desc: meta_desc,
    url: `${process.env.REACT_APP_WEB_URL}${location?.pathname}`
  });

  useEffect(() => {
    const getSeoData = async () => {
      try {
        const res = await axios.get(`${getSeo}${process.env.REACT_APP_WEB_URL}${location.pathname}`);
        const data = res?.data?.data?.[0];
        if (data) {
          setSeoData({
            meta_title: data.meta_title || meta_title,
            meta_desc: data.meta_desc || meta_desc,
            url: data.url || `${process.env.REACT_APP_WEB_URL}${location?.pathname}`
          });
        }
        else {
          setSeoData({
            meta_title: meta_title,
            meta_desc: meta_desc,
            url: `${process.env.REACT_APP_WEB_URL}${location?.pathname}`
          });
        }
      } catch (error) {
        console.error("SEO data fetch error:", error);
      }
    };

    getSeoData();
  }, [location]);

  return (
    <>
      <Helmet defer>
        <title>{seoData.meta_title}</title>
        <meta name="title" content={seoData.meta_title} />
        <meta name="description" content={seoData.meta_desc} />
        <link rel="canonical" href={seoData.url} />
      </Helmet>
      <Header />
      <main>
        <Outlet />
      </main>
      <Footer />
    </>
  );
};

export default AppLayout;
